import {
  ElementsPanelParams,
  openElementsPanel,
} from '../../blocks/common/elements-panel';
import { EditorAppContext } from '../../types/editor-app-context.type';
import { POST_PAGE_SELECTORS } from './post-page-constants';

export const openPostPageElementsPanel = async (
  context: EditorAppContext,
  widgetRef: ElementsPanelParams['widgetRef'],
) =>
  openElementsPanel(context, {
    widgetRef,
    categoriesData: [],
    elementsData: [
      {
        label: COMPS_DATA.postHeader.label,
        identifier: { role: COMPS_DATA.postHeader.role },
        index: 0,
      },
      {
        label: COMPS_DATA.postContent.label,
        identifier: { role: COMPS_DATA.postContent.role },
        index: 1,
      },
      {
        label: COMPS_DATA.postFooter.label,
        identifier: { role: COMPS_DATA.postFooter.role },
        index: 2,
      },
      {
        label: COMPS_DATA.relatedPosts.label,
        identifier: { role: COMPS_DATA.relatedPosts.role },
        index: 3,
      },
    ],
  });

const COMPS_DATA = {
  postHeader: { role: POST_PAGE_SELECTORS.POST_HEADER, label: 'Header' },
  postContent: {
    role: POST_PAGE_SELECTORS.POST_CONTENT,
    label: 'Content',
  },
  postFooter: { role: POST_PAGE_SELECTORS.POST_FOOTER, label: 'Footer' },
  relatedPosts: {
    role: POST_PAGE_SELECTORS.RELATED_POSTS,
    label: 'Related Posts',
  },
};
